<template>
  <div>
    <label class="label" :class="labelClass" v-if="labelTr">{{labelTr}}</label>
    <div class="control" :class="controlClass">
      <div class="dropdown" :class="dropdownClass">
        <div class="field has-addons">
          <div class="control">
            <input
              :id="id"
              :name="name"
              :autocomplete="autocomplete"
              :placeholder="placeholderTr"
              class="input"
              :class="inputClass"
              type="text"
              :aria-describedby="nameDesc"
              :disabled="disabled"
              v-model="query"
              v-on="listeners"
              @input="search"
              @keyup.enter="selectCurrent"
            >
            <span class="icon is-small is-left" v-if="icon">
              <i class="fa" :class="iconClass"></i>
            </span>
            <span class="icon is-small is-right" v-if="valid">
              <i class="fal fa-check"></i>
            </span>
                    <div class="dropdown-menu">
          <div class="dropdown-content">
            <a
              v-for="opt in results"
              :key="opt.id"
              class="dropdown-item"
              @click="select(opt)"
            >{{opt.label}}</a>
          </div>
        </div>

          </div>
          <div class="control">
            <sqr-button icon="chevron-down" @click="active = !active"/>
          </div>
        </div>
        <!-- <p class="help" v-if="helper">{{helperTr}}</p> -->
      </div>
    </div>
  </div>
</template>

<script>
import input from "./mixins/input";
import options from "./mixins/options";

import SqrButton from "./SqrButton";

export default {
  name: "SqrInputSelect",
  mixins: [input, options],
  data() {
    return {
      active: false,
      query: ""
    };
  },
  computed: {
    results() {
      let query = this.query;
      let results = this.mappedOptions;
      if (query) {
        let pattern = new RegExp("\\b" + query);
        results = results.filter(opt => pattern.test(opt.label), "i");
      }
      return results.slice(0, 5);
    },
    dropdownClass() {
      return {
        "is-active": this.active
      };
    }
  },
  mounted() {
    this.resetQuery(this.value);
  },
  watch: {
    value(n) {
      this.resetQuery(n);
    },
    mappedOptions(n) {
      this.resetQuery(this.value);
    }
  },
  methods: {
    search() {
      this.active = true;
    },
    select(opt) {
      this.query = opt.label;
      this.$emit("select", opt.value, opt.orig);
      this.$emit("change", opt.value, opt.orig);
      this.active = false;
    },
    selectCurrent() {
      if (this.results.length > 0) {
        this.select(this.results[0]);
      }
      this.active = false;
    },
    resetQuery(value) {
      let option = this.mappedOptions.find(opt => opt.value === value);
      if (option) {
        this.query = option.label;
      }
    }
  }
};
</script>
