<template>
  <main class="">
    <sqr-error :error="polLoadError" @retry="sub({ id: this.id })" />
    <section class="md:m-4" v-if="!loading && !polLoadError && policy">
      <div class="bg-white overflow-hidden shadow sm:rounded-lg">
        <div class="px-4 py-5 sm:p-6">
          <div class="flex -mx-4">
            <div class="flex-1 px-4">
              <sqr-input-select
                class="mb-3"
                label="policy_insurer"
                :value="policy.insurerId"
                :options="insurers"
                value-key="id"
                label-key="company"
                @change="insurerSet"
              />
              <sqr-input-select
                class="mb-3"
                label="policy_branch"
                :value="policy.branchId"
                :options="branches"
                value-key="id"
                label-key="abrev"
                @change="branchSet"
              />
              <sqr-input-text
                class="mb-3"
                :label="$t('policy_number')"
                :value="policy.number"
                @change="fieldSet({ field: 'number', value: $event })"
              />
            </div>
            <div class="flex-1 px-4">
              <sqr-input-date
                class="mb-3"
                :label="$t('policy_date_start')"
                :value="policy.dateStart"
                @change="fieldSet({ field: 'dateStart', value: $event })"
              />
              <sqr-input-date
                class="mb-3"
                :label="$t('policy_date_until')"
                :value="policy.dateEnd"
                @change="fieldSet({ field: 'dateEnd', value: $event })"
              />
              <sqr-input-number
                class="mb-3"
                type="float"
                :label="$t('policy_premium')"
                :value="policy.premium"
                @change="fieldSet({ field: 'premium', value: $event })"
              />
            </div>
            <div class="flex-1 px-4">
              <sqr-input-textarea
                class="mb-3"
                :label="$t('policy_note')"
                :value="policy.note"
                @change="fieldSet({ field: 'note', value: $event })"
              />
            </div>
          </div>
          <div class="flex pt-8 justify-end">
            <sqr-button
              v-show="!policy.archived"
              icon="box"
              :label="$t('relation_archive')"
              size="md"
              color="red"
              @click="archiveSet(true)"
              :loading="saving"
              class="mr-6"
            />
            <sqr-button
              v-show="policy.archived"
              icon="box-open"
              :label="$t('relation_unarchive')"
              size="md"
              color="green"
              @click="archiveSet(false)"
              :loading="saving"
              class="mr-6"
            />
            <sqr-button
              :label="$t('buttons_cancel')"
              @click="$router.back()"
              color="white"
              size="medium"
              class="mr-r"
            />
            <sqr-button
              :label="$t('buttons_save')"
              @click="saveAndGo()"
              color="primary"
              size="md"
              :loading="saving"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="border-t mt-16 py-4 bg-white">
      <documents :bid="bid" :folder="pid" has-email @email="email()" />
    </section>
  </main>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';

import relation from './relation';

import SqrButton from '@/sqrd-ui/SqrButton';
import SqrInputSelect from '@/sqrd/SqrInputSelect';
import SqrInputText from '@/sqrd-ui/SqrInputText';
import SqrInputDate from '@/sqrd-ui/SqrInputDate';
import SqrInputNumber from '@/sqrd-ui/SqrInputNumber';
import SqrInputTextarea from '@/sqrd-ui/SqrInputTextarea';

import Documents from '@/components/Documents';

export default {
  name: 'RelationPolicy',
  mixins: [relation],
  components: {
    SqrButton,
    SqrInputSelect,
    SqrInputText,
    SqrInputDate,
    SqrInputNumber,
    SqrInputTextarea,
    Documents
  },
  props: {
    bid: String,
    rid: String,
    pid: String
  },
  computed: {
    ...mapGetters('policy', {
      id: 'id',
      policy: 'record',
      polLoading: 'loading',
      polLoadError: 'loadError',
      updates: 'updates',
      sending: 'sending'
    }),

    loading() {
      return this.polLoading || this.relLoading;
    },

    ...mapGetters('insurers', {
      insurers: 'records',
      insurersLoading: 'loading'
    }),
    ...mapGetters('branches', {
      branches: 'records',
      branchesLoading: 'loading'
    }),

    title() {
      return this.$t('relation_policy_title', this.policy);
    },
    subtitle() {
      return this.$t('relation_policy_subtitle', this.relation);
    }
  },
  mounted() {
    this.polSub({ path: ['brokers', this.bid], id: this.pid });
    this.insSub({ path: ['brokers', this.bid] });
    this.braSub({ path: ['brokers', this.bid] });
  },
  methods: {
    ...mapActions('policy', { polSub: 'sub', send: 'send' }),

    ...mapActions('insurers', { insSub: 'sub' }),
    ...mapActions('branches', { braSub: 'sub' }),

    ...mapMutations('policy', ['fieldSet']),
    insurerSet(id, opt) {
      this.fieldSet({ field: 'insurerId', value: id });
      this.fieldSet({ field: 'insurerName', value: opt.company });
    },
    branchSet(id, opt) {
      this.fieldSet({ field: 'branchId', value: id });
      this.fieldSet({ field: 'branchName', value: opt.name });
      this.fieldSet({ field: 'branchAbrev', value: opt.abrev });
    },
    archiveSet(archived = true) {
      this.fieldSet({ field: 'archived', value: archived });
      this.saveAndGo();
    },
    ...mapActions('policiesIndex', ['reindex']),
    async saveAndGo() {
      const value =
        (this.policy &&
          this.policy.number &&
          this.policy.number.replace(/\W/g, '')) ||
        '';
      this.fieldSet({ field: 'numberAlt', value });
      const bid = this.bid;
      const id = this.pid;
      const doc = this.policy;
      await this.send({});
      await this.reindex({ bid, id, doc });
      this.$router.back();
    },
    email() {
      this.$router.push({
        name: 'relation-email',
        params: {
          bid: this.bid,
          rid: this.rid
        },
        query: { templateId: 'vPCOw5qxTVkugZBTI6ZZ' }
      });
    }
  },
  metaInfo() {
    return {
      title: this.title + ' ' + this.subtitle
    };
  }
};
</script>
